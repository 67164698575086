import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const dataFetcher = () => {
    const { showGlobalToast } = globalToast();

    const fetchData = (fetchObj: any) => {
        const { abadirfiles } = fetchObj;
        if(abadirfiles == 'abadis'){
            store.dispatch('di_abadisuploadsinfo', []);
        } else {
            store.dispatch('di_filesuploadsinfo', []);
        }

        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const requestObj = {
            type: 'uploadsinfo',
            request: 'getinfodatauploads',
            validateusername: username,
            validatepassword: password,
            fetchObj
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                const { database, datainfolist } = responseObj;
                if(database == 'abadis'){
                    store.dispatch('di_abadisuploadsinfo', datainfolist);
                } else {
                    store.dispatch('di_filesuploadsinfo', datainfolist);
                }
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { fetchData }
}

export default dataFetcher;