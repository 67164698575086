
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import './DataUploadsInfo.scss';

import { Chart, registerables } from 'chart.js'
import { LineChart } from 'vue-chart-3';

import dataFetcher from '@/composables/datauploadsinfo/dataFetcher';
import renderDateFromServer from '@/composables/common/renderDateFromServer';
import downloadSingleCertificate from '@/composables/datauploadsinfo/downloadSingleCertificate';
import abadiRouter from '@/composables/datauploadsinfo/abadiRouter';
import filesManager from '@/composables/abadifiles/filesManager';

export default defineComponent({
    components: { LineChart },
    setup() {
        Chart.register(...registerables);

        const { fetchData } = dataFetcher();
        const { renderDate } = renderDateFromServer();
        const { downloadCertificate } = downloadSingleCertificate();
        const { goToAbadi } = abadiRouter();
        const { checkNApproveFile, checkNDownloadFile } = filesManager();

        const defaultLimit = 50;
        const defalutAbadirRFiles = 'abadis';

        const abadirfiles = ref(defalutAbadirRFiles);
        const fromdate = ref('');
        const todate = ref('');
        const datadistrict = ref('');
        const datataluk = ref('');
        const limit = ref(defaultLimit);

        const g_districtsList = computed(() => store.getters.g_districtsList);
        const g_relatedTalukList = computed(() => {
            datataluk.value = '';

            const taluksList = store.getters.g_taluksList;
            const relatedTaluks = taluksList.filter((taluk: any) => taluk.district == datadistrict.value);
            return relatedTaluks;
        });

        const callFetchData = () => {
            const fetchObj = {
                abadirfiles: abadirfiles.value,
                fromdate: fromdate.value,
                todate: todate.value,
                datadistrict: datadistrict.value,
                datataluk: datataluk.value,
                limit: limit.value
            };

            fetchData(fetchObj);
        }

        const clearFilter = () => {
            abadirfiles.value = defalutAbadirRFiles;
            fromdate.value = '';
            todate.value = '';
            datadistrict.value = '';
            datataluk.value = '';
            limit.value = defaultLimit;
        }

        const di_abadisuploadsinfo = computed(() => store.getters.di_abadisuploadsinfo);
        const di_filesuploadsinfo = computed(() => store.getters.di_filesuploadsinfo);

        const abadisLineChartRef = ref();
        const filesLineChartRef = ref();

        const abadisChartData = computed(() => {
            const di_abadisuploadsinfo = store.getters.di_abadisuploadsinfo;
            let chartMetaData = <any>{};
            for (let i = 0; i < di_abadisuploadsinfo.length; i++){
                const { serverdate } = di_abadisuploadsinfo[i];
                
                if(serverdate in chartMetaData){
                    chartMetaData[serverdate] += 1;
                } else {
                    chartMetaData[serverdate] = 1;
                }
            }

            const chartData = Object.keys(chartMetaData).map(key => {
                return { 
                    x: renderDate(key),
                    y: chartMetaData[key]
                }
            });

            return {
                datasets: [
                    {
                        label: 'Abadi Limits Uploads',
                        data: chartData
                    },
                ],
            }}
        );

        const filesChartData = computed(() => {
            const di_filesuploadsinfo = store.getters.di_filesuploadsinfo;
            let chartMetaData = <any>{};
            for (let i = 0; i < di_filesuploadsinfo.length; i++){
                const { serverdate } = di_filesuploadsinfo[i];
                
                if(serverdate in chartMetaData){
                    chartMetaData[serverdate] += 1;
                } else {
                    chartMetaData[serverdate] = 1;
                }
            }

            const chartData = Object.keys(chartMetaData).map(key => {
                return { 
                    x: renderDate(key),
                    y: chartMetaData[key]
                }
            });

            return {
                datasets: [
                    {
                        label: 'Abadi Files Uploads',
                        data: chartData
                    },
                ],
            }}
        );

        const callDownloadCertificate = (e: any) => {
            let abadilimituuid = e.target.getAttribute('abadilimituuid');
            // console.log(abadilimituuid);
            downloadCertificate(abadilimituuid);
        }

        const callGoToAbadi = (e: any) => {
            let abadilimituuid = e.target.getAttribute('abadilimituuid');
            // console.log(abadilimituuid);
            goToAbadi(abadilimituuid);
        }
        
        const callDownloadFile = (e: any) => {
            let fileFullName = e.target.getAttribute('filelocation') + '.' + e.target.getAttribute('fileextension');
            // console.log(fileFullName);
            checkNDownloadFile(fileFullName);
        }

        return {
            abadirfiles, fromdate, todate,datadistrict, datataluk, limit,
            g_districtsList, g_relatedTalukList,
            callFetchData, clearFilter,
            di_abadisuploadsinfo, di_filesuploadsinfo,
            renderDate,
            abadisChartData, abadisLineChartRef,
            filesChartData, filesLineChartRef,
            callDownloadCertificate, callGoToAbadi,
            callDownloadFile
        }
    },
})
